.chat-ai-predictions {
    background: linear-gradient(120deg, #24C9FF 0%, #1A1558 100%);
    text-align: center;
    color: white;
    padding-bottom: 50px;
  
//   body {
//     background: linear-gradient(120deg, #24C9FF 0%, #1A1558 100%);
//     color: #ffffff;
//     line-height: 1.6;
//     text-align: center;
//     padding-top: 50px;
//     min-height: 100vh;
//     display: flex;
//     align-items: center;
//     justify-content: flex-start;
//     flex-direction: column;
//     padding-bottom: 50px;
//   }
  
  // Navbar styles
  .navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 50px;
    width: 100%;
  
    .navbar-group {
      display: flex;
      align-items: center;
  
      .navbar-logo img {
        height: 50px;
      }
  
      .navbar-text {
        font-size: 1.5rem;
        margin-left: 10px;
        margin-bottom: 10px;
        padding-bottom: 0;
      }
    }
  
    .navbar-btn {
      padding: 10px 20px;
      background-color: #24A1DE;
      color: white;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      font-size: 1rem;
      transition: background-color 0.3s ease;
  
      &:hover {
        background-color: #0056b3;
      }
    }
  }
  
  // Wrapper styles
  .wrapper {
    margin: 0 auto;
    text-align: center;
    width: 950px;
    padding-top: 70px;
  
    h1 {
      font-size: 48px;
      font-weight: 600;
      margin-bottom: 20px;
    }
  
    p {
      font-size: 18px;
      margin-bottom: 20px;
      opacity: 0.9;
    }
  
    iframe {
      width: 100%;
      height: 100%;
      min-height: 700px;
      border: none;
    }
  }

  //General button styles
  .predictions-btn {
    padding: 10px 20px;
    background-color: #24A1DE;
    color: white;
    border-radius: 10px;
    margin: 5px auto 0;
  }
  
  // Form styles
  #lead-form,
  #availability-box {
    width: 100%;
    max-width: 400px;
    background: rgba(255, 255, 255, 0.2);
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 36px rgba(0, 0, 0, 0.1);
    margin: 20px auto;
    text-align: center;
  
    label {
      font-size: 18px;
      margin-bottom: 10px;
      display: block;
    }
  
    input[type="text"],
    input[type="email"] {
      font-size: 16px;
      padding: 10px;
      border: none;
      border-radius: 5px;
      width: calc(100% - 20px);
      margin-bottom: 20px;
    }
  
    button {
      padding: 10px 20px;
      font-size: 18px;
      color: #000;
      background: #FFD521;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      transition: all 0.3s ease;
  
      &:hover {
        opacity: 0.9;
      }
    }
  
    #message {
      font-size: 16px;
      margin-top: 20px;
      color: #ffffff;
    }
  }
  
  // Fonts
  .oswald-regular {
    font-family: "Oswald", sans-serif;
    font-weight: 200;
  }
  
  .oswald-bold {
    font-family: "Oswald", sans-serif;
    font-weight: 400;
  }
  
  .jockey-one-regular {
    font-family: "Jockey One", sans-serif;
    line-height: initial;
    color: #ffffff;
    font-size: 30px;
  }
  
  // Status container
  .status-container {
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: center;
  
    .ring-container {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      padding-right: 20px;
  
      .circle {
        width: 15px;
        height: 15px;
        background-color: #18D58A;
        border-radius: 50%;
        margin: -20px -20px 0 -20px;
      }
  
      .ringring {
        border: 3px solid #18D58A;
        border-radius: 30px;
        height: 25px;
        width: 25px;
        animation: pulsate 1s ease-out infinite;
        position: relative;
      }
    }
  }
  
  // Pulsate animation
  @keyframes pulsate {
    0% {
      transform: scale(0.1);
      opacity: 0.0;
    }
    50% {
      opacity: 1.0;
    }
    100% {
      transform: scale(1.2);
      opacity: 0.0;
    }
  }
  
  // Responsive styles
  @media (max-width: 950px) {
    .wrapper {
      width: 100%;
      padding: 0 15px;
  
      h1 {
        font-size: 32px;
      }
  
      p {
        font-size: 16px;
      }
    }
  
    .navbar {
      max-width: 1200px;
      margin: 0 auto;
    }
  }
}